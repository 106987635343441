<template>
  <div class="columns is-multiline">
    <div class="column is-12">
      <p class="title is-6">School Fee Details</p>
    </div>
    <div class="column is-12">
      <div
        class="columns"
        v-for="item in Object.entries(student.schoolFee)"
        :key="item.id"
      >
        <div class="column is-3 primary py-0 py-1">
          <strong>{{ normalizeText(item[0]) }}</strong>
        </div>
        <div class="column is-9 text-bg-color py-0 py-1">
          {{ convertToCurrency(item[1], '₦') }}
        </div>
      </div>
    </div>
    <div class="column is-12 pr-0" v-if="role === 'admin'">
      <button
        type="submit"
        class="button btn-120 is-success is-capitalized is-pulled-right"
        @click="initializeSchoolFeeDetail()"
      >
        Update
      </button>
    </div>
    <div class="column is-12">
      <p class="title is-6">Total Fee Paid</p>
    </div>
    <div class="column is-12 has-background-light">
      {{ convertToCurrency(student.totalSchoolFeesPaid, '₦') }}
    </div>
    <template v-if="role === 'admin'">
      <div class="column is-12 mt-5">
        <p class="title is-6">School Fee Payment</p>
      </div>
      <div class="column is-12 px-0">
        <b-table :data="student.termSchoolFeePayments" hoverable>
          <b-table-column field="#" label="#" width="40" numeric v-slot="props">
            {{ student.termSchoolFeePayments.indexOf(props.row) + 1 }}
          </b-table-column>

          <b-table-column label="REF" v-slot="props">{{
            props.row.reference.toUpperCase()
          }}</b-table-column>

          <b-table-column label="ATT" v-slot="props">{{
            props.row.acctTransactionType
          }}</b-table-column>

          <b-table-column label="AMT" v-slot="props">{{
            convertToCurrency(props.row.amount, '₦')
          }}</b-table-column>

          <b-table-column label="AMTP" v-slot="props">{{
            convertToCurrency(props.row.amountPaid, '₦')
          }}</b-table-column>

          <b-table-column label="BAL" v-slot="props">{{
            convertToCurrency(props.row.balance, '₦')
          }}</b-table-column>

          <b-table-column label="Actions" width="160" v-slot="props">
            <action-buttons
              @initialize-info="initializeReceipt(props.row)"
              @initialize-update="initializeUpdateSchoolFee(props.row)"
              @initialize-remove="initializeRemoveSchoolFee(props.row)"
            />
          </b-table-column>
        </b-table>
      </div>
      <div class="column is-12">
        <button
          type="submit"
          class="button btn-120 is-success is-capitalized is-pulled-right"
          @click="initializeSchoolFeePayment()"
        >
          Pay
        </button>
      </div>
    </template>
    <div class="column is-12 pr-0" v-if="role === 'parent'">
      <button
        type="submit"
        class="button btn-120 is-success is-capitalized is-pulled-right"
        @click="makePayment()"
      >
        Pay
      </button>
    </div>
    <app-modal
      id="remove-school-fee"
      modal-title="Student"
      context="remove"
      @remove="remove"
      :show-modal.sync="isModalOpen"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { toCurrency } from '@/assets/js/currency_formatter'
import { crudNotification } from '@/assets/js/notification'

export default {
  data() {
    return {
      student: {
        feeTemplate: null,
        schoolFee: {},
        termSchoolFeePayments: [],
      },
    }
  },
  props: {
    role: {
      type: String,
      default: '',
    },
    schoolId: {
      type: Number,
    },
  },
  methods: {
    normalizeText(value) {
      return value.replace(/\_/g, ' ')
    },
    convertToCurrency(value, symbol) {
      return toCurrency(value, symbol)
    },
    initializeUpdateSchoolFee(school_fee) {
      this.$router.push(
        `/school/${this.schoolId}/student/${this.student.id}/school_fee/${school_fee.id}`
      )
    },
    initializeSchoolFeePayment() {
      if (this.student.termSchoolFeePayments.length === 3) {
        this.$buefy.toast.open({
          message: 'Maximum number of 3 installments reached.',
          type: 'is-info',
        })
        return
      }
      if (this.student.feeTemplate) {
        this.$router.push(
          `/school/${this.schoolId}/student/${this.student.id}/school_fee/new`
        )
      } else {
        this.$buefy.toast.open({
          message: 'No Fee Template for the Class group.',
          type: 'is-warning',
        })
      }
    },
    initializeRemoveSchoolFee(school_fee) {
      // this.removeStudent = false
      // this.$store.commit('setAppModalActive', true)
      // this.context = 'remove'
      // this.id = school_fee.id
      console.log('remove')
      this.context = 'remove'
      this.id = school_fee.id
      this.openModal()
    },
    initializeReceipt(school_fee) {
      const params = {
        id: school_fee.id,
        school_id: this.schoolId,
        student_id: this.student.id,
        school_class_id: this.student.schoolClass.id,
        term_id: this.student.term.id,
        status: this.schoolFeeStatus(school_fee),
        context: 'SINGLE SCHOOL FEE RECEIPT',
      }

      this.$store.commit('setRecord', params)

      // TODO: Should be persisted

      this.$router.push(`/school/${this.schoolId}/pdf_report`)
    },
    initializeSchoolFeeDetail() {
      if (this.student.feeTemplate) {
        this.$router.push(
          `/school/${this.schoolId}/school_fee_detail/${this.student.id}`
        )
      } else {
        this.$buefy.toast.open({
          message: 'No Fee Template for the Class group.',
          type: 'is-warning',
        })
      }
    },
    schoolFeeStatus(schoolFee) {
      if (schoolFee.balance === 0) {
        return 'Full'
      } else {
        return 'Owing'
      }
    },
    itemValue(value) {
      if (!value) return
      if (typeof value === 'object') {
        const { name } = value
        value = name
      }
      return value
    },
    remove() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation DeleteSchoolFee($id: Int!) {
              deleteSchoolFee(input: { id: $id }) {
                schoolFee {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: parseInt(this.id),
          },
        })
        .then((response) => {
          crudNotification(
            response.data.deleteSchoolFee.errors,
            'Successfully deleted.'
          ).then(() => {
            this.closeModal()
            this.$apollo.queries.student.refetch()
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
    makePayment() {
      this.$router.push(
        `/school/${this.schoolId}/school_fee_payment/${this.student.id}`
      )
    },
  },
  mounted() {
    this.$apollo.addSmartQuery('student', {
      query: gql`
        query StudentQuery($id: ID!) {
          student(id: $id) {
            id
            feeTemplate
            totalSchoolFeesPaid
            schoolFee
            termSchoolFeePayments {
              id
              reference
              acctTransactionType
              amount
              amountPaid
              balance
            }
          }
        }
      `,
      fetchPolicy: 'cache-and-network',
      variables() {
        return { id: this.$route.params.id }
      },
      error(error) {
        this.$buefy.notification.open({
          duration: 5000,
          message: error.message,
          position: 'is-top-right',
          type: 'is-danger',
          hasIcon: true,
        })
      },
    })
  },
}
</script>
